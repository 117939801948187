import { useHistory, useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectPermission } from 'redux/global';

export const usePath = () => {
  const homePaths = ['/signin', '/register', '/signup', '/forgot', '/addH', '/pricing', '/unsubscribe'];
  const location = useLocation();
  const history = useHistory();
  const permission = useAppSelector(selectPermission);

  const isHomePath = !!(location.pathname === '/' || homePaths.find((p) => location.pathname.includes(p)));

  const navigateToHome = (perm?: number | null) => {
    const permission1 = (perm || perm === 0) ? perm : permission;
    if (permission1 === 0) {
      history.push('/logs');
      return;
    } if (!permission1) {
      history.push('/');
      return;
    } else if (permission1 <= 3) {
      history.push('/rooms');
      return;
    }
    history.push('/dashboard');
  };

  return { isHomePath, navigateToHome };
};
