import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IResponse } from 'types';
import React from 'react';
import { callEndpoint } from 'redux/global';
import { RootState } from 'redux/store';

export type Metric = {
  startDate: Date,
  endDate: Date
}
export type ModalParts = {
  component: React.JSX.Element,
  exitOnBgClick?: Function,
  noBg?: boolean,
  skipSideBar?: true
}

export type Branch = {
  name: string,
  staff: string[]
}

export type HotelData = {
  id: string,
  name: string,
  password: string,
  nameSave?: string,
  address: string,
  email: string,
  username?: string,
  linkedin?: string | null,
  facebook?: string | null,
  instagram?: string | null,
  twitter?: string | null,
  phoneNumber: string,
  logo?: string,
  accNumber?: string | null,
  accName?: string | null,
  field1?: string | null,
  field2?: string | null,
  currency?: string | null,
  displayEmail?: string,
  prefs: object,
  branches: Branch[],
  fields: object,
  deal: string,
  country: string,
  region: string,
  branch: string,
  expires?: string,
  billingDate?: string,
  maxRooms?: string | null,
  suffix?: string | null,
  city: string,
  coId?: string,
  channelExpiry?: string,
  keyCardUrl?: string,
  keyCardExpiry?: string,
}
export interface ViewsState {
  socket: any,
  isSocketConnected: boolean,
  metricTime: Metric | null,
  debugText: any[],
  hotelData: HotelData | null,
  modalParts: ModalParts | null
}

const oneMonthAgo = new Date();
oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);
oneMonthAgo.setHours(0, 0, 0, 0);
const lateEndD = new Date();
lateEndD.setHours(23, 59, 59, 999);

const initialState: ViewsState = {
  socket: null,
  isSocketConnected: true,
  metricTime: {
    startDate: oneMonthAgo,
    endDate: lateEndD,
  },
  debugText: [],
  hotelData: null,
  modalParts: null
};

export const counterSlice = createSlice({
  name: 'views',
  initialState,
  reducers: {
    setSocket: (state, action) => {
      state.socket = action.payload;
    },
    setIsSocketConnected: (state, action: PayloadAction<boolean>) => {
      state.isSocketConnected = action.payload;
    },
    setMetricTime: (state, action: PayloadAction<Metric | null>) => {
      state.metricTime = action.payload;
    },
    setDebugText: (state, action: PayloadAction<any[]>) => {
      state.debugText = action.payload;
    },
    setHotelData: (state, action: PayloadAction<HotelData | null>) => {
      if (action.payload) {
        localStorage.setItem('hotel_data_id', action.payload.id.toString());
      } else {
        localStorage.removeItem('hotel_data_id');
      }
      state.hotelData = action.payload;
    },
    setModalParts: (state, action: PayloadAction<ModalParts | null>) => {
      state.modalParts = action.payload;
    }
  },
});

export const {
  setSocket,
  setIsSocketConnected,
  setMetricTime,
  setDebugText,
  setHotelData,
  setModalParts
} = counterSlice.actions;

export const selectSocket = (state: RootState) => state.views.socket;
export const selectIsSocketConnected = (state: RootState) => state.views.isSocketConnected;
export const selectMetricTime = (state: RootState) => state.views.metricTime;
export const selectDebugText = (state: RootState) => state.views.debugText;
export const selectHotelData = (state: RootState) => state.views.hotelData;
export const selectModalParts = (state: RootState) => state.views.modalParts;
export const selectCurrency = (state: RootState) => state.views.hotelData?.currency || '$';

export default counterSlice.reducer;

export const setBranches = (branches: string) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'setbranches',
      method: 'POST',
      body: { branches }
    }),
  );
  return res;
};

export const addTmpHotel = (hotelData: HotelData) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'addTMPhotel',
      method: 'POST',
      body: { hotelData }
    }),
  );
  return res;
};

export const transferTmpHotel = (id: string) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'transferTMPhotel',
      method: 'POST',
      body: { id }
    }),
  );
  return res;
};

export const handlePropertyCO = () => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'handleproperty',
      method: 'GET'
    }),
  );
  return res;
};
