import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  getInfo,
  selectGroups,
  selectRates,
  selectRoomTypes,
  selectUsers,
  setGroups, setRates, setRoomTypes, setUsers, verifyPayment,
  Services,
  setServices,
  defaultServices,
  selectServices,
  selectSettingsParts,
  setSettingsParts,
  User,
} from './redux';
import { closePaymentModal, useFlutterwave } from 'pages/components/Flutterwave';
import { FlutterwaveConfig } from 'pages/components/Flutterwave/types';
import { selectHotelData } from 'pages/redux';
import { getTaxRef } from 'utils';
import Bed from '@mui/icons-material/Hotel';
import House from '@mui/icons-material/Apartment';
import Balcony from '@mui/icons-material/Balcony';
import Accessible from '@mui/icons-material/Accessible';
import AcUnit from '@mui/icons-material/AcUnit';
import Fridge from '@mui/icons-material/Kitchen';
import Family from '@mui/icons-material/FamilyRestroom';
import Tv from '@mui/icons-material/Tv';
import { toast } from 'react-toastify';
import Group from '@mui/icons-material/SwapHoriz';
import { selectUserEmail } from 'redux/global';

export const groupMax = 6;

export const useGroupOptions = () => {
  const groups = useAppSelector(selectGroups);

  const setPerksCall = (perk: string, perks: string[], setPerks: Function) => {
    if (perks.includes(perk)) {
      setPerks(perks.filter((p) => p !== perk));
      return;
    }
    if (perks.length === groupMax) {
      toast(`you can only set ${groupMax} groups`, { type: 'error' });
    } else {
      const newPerks = [...perks];
      newPerks.push(perk);
      setPerks(newPerks);
    }
  };

  const groupOptions0 = [
    {
      name: '2 beds',
      icon: <Bed fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'Large bed',
      icon: <Bed fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'Split beds',
      icon: <Bed fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: '1 bed',
      icon: <Bed fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'Balcony',
      icon: <Balcony fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'Large room',
      icon: <House fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'Accessible',
      icon: <Accessible fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'A/C unit',
      icon: <AcUnit fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'Big TV',
      icon: <Tv fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'Fridge',
      icon: <Fridge fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'Family OK',
      icon: <Family fontSize="small" />,
      onClick: setPerksCall
    },
  ];

  const groupOptions = [
    ...groupOptions0,
    ...Array.from(new Set(groups)).map((g) => {
      return {
        name: g,
        icon: <Group fontSize="small" />,
        onClick: setPerksCall
      };
    })
  ];

  return { groupOptions, groups };
};

export const useUpdateSettings = () => {
  const settingsParts = useAppSelector(selectSettingsParts);
  const dispatch = useAppDispatch();

  const updateLocalSettings = (part: Record<string, any>) => {
    dispatch(setSettingsParts({ ...settingsParts, ...part }));
  };

  return { updateLocalSettings };
};

export const useSettings = () => {
  const users = useAppSelector(selectUsers);
  const groups = useAppSelector(selectGroups);
  const roomTypes = useAppSelector(selectRoomTypes);
  const rates = useAppSelector(selectRates);

  return {
    users,
    roomTypes,
    groups,
    rates,
  };
};

export const useFetchSettings = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasFetchedUsers, setHasFetchedUsers] = useState(false);
  const hotelData = useAppSelector(selectHotelData);
  const users = useAppSelector(selectUsers);
  const services = useAppSelector(selectServices);
  const dispatch = useAppDispatch();

  const refreshServices = () => {
    const now = new Date();
    const thisMidnight = new Date(now);
    thisMidnight.setHours(23, 59, 59, 999);
    const earlyToday = new Date();
    earlyToday.setHours(0, 0, 0, 0);

    const timeTillMidnight = +thisMidnight - +now;

    setTimeout(() => {
      dispatch(setServices({
        meals: {
          date: earlyToday,
          ids: [],
          priorIds: services.meals.ids
        }
      }));
      setTimeout(() => refreshServices(), 10);
    }, timeTillMidnight || 1);
  };

  useEffect(() => {
    const f = async () => {
      setHasFetchedUsers(true);
      setIsLoading(true);
      const res = await dispatch(getInfo());
      setIsLoading(false);
      if (res.status === 'success') {
        dispatch(setUsers([...res.data.users.map((u: User) => {
          return {
            ...u,
            notifications: JSON.parse(u.notifications as any || '[]')
          };
        })
        ]));
        setHasFetchedUsers(false);
        dispatch(setGroups(res.data.groups));
        dispatch(setRoomTypes(res.data.roomTypes));
        dispatch(setRates(res.data.rates));
        const dbServives = res.data.services as Services;
        if (+dbServives.meals.date < +new Date()) {
          dispatch(setServices(defaultServices));
        } else {
          dispatch(setServices(dbServives));
        }
        refreshServices();
        return;
      }
      toast(res.data, { type: 'error' });
    };

    if (!users && hotelData && !hasFetchedUsers) f();
  }, [hotelData]);

  return { isLoading };
};

type PaymentDetails = {
  flutterTitle: string,
  flutterDesc: string,
  paymentDescription: string,
  totalCost: number,
}
export const usePayment = (
  {
    finalFunction,
    onExit,
    paymentDetails,
  } : {
    // eslint-disable-next-line no-unused-vars
    finalFunction: (closePayment: () => void) => void,
    onExit: Function,
    paymentDetails: PaymentDetails,
  }
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleFlutterPayment = useFlutterwave();
  const dispatch = useAppDispatch();
  const hotelData = useAppSelector(selectHotelData);
  const userEmail = useAppSelector(selectUserEmail);
  const {
    totalCost, flutterTitle: title, flutterDesc: description, paymentDescription
  } = paymentDetails;

  const flutterPayment = async (config: FlutterwaveConfig) => {
    handleFlutterPayment({
      flutterWaveConfig: config,
      callback: async (resp: Record<string, any>) => {
        const res0 = await dispatch(verifyPayment(resp));
        if (res0.status === 'success' && res0.data === 'pass') {
          finalFunction(() => closePaymentModal());
        } else {
          toast(`Something went wrong. ERROR: ${res0.status}`, { type: 'error' });
          closePaymentModal(); // this will close the modal programmatically
        }
        setIsLoading(false);
      },
      onClose: () => {
        setIsLoading(false);
        onExit();
      },
    });
  };

  const beginPayment = () => {
    setIsLoading(true);
    const flutterConfig = {
      public_key: process.env.REACT_APP_FW_PUBLIC_KEY || '',
      tx_ref: getTaxRef(
        `${hotelData?.id || 'Error'}_!${title}!_DETAILS:${paymentDescription}`,
        userEmail,
        `N${totalCost.toLocaleString()}`
      ),
      amount: totalCost,
      currency: 'NGN',
      payment_options: 'card',
      customer: {
        email: userEmail,
      },
      // redirect_url: `${process.env.REACT_APP_API}postpayment?amount=${totalCost}`,
      meta: { counsumer_id: '7898', consumer_mac: 'kjs9s8ss7dd' },
      customizations: {
        title,
        description,
        logo: 'https://lodgefirst.com/xyder/lodge.png'
        // logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
      },
    };
    flutterPayment(flutterConfig);
  };
  return { isLoading, beginPayment };
};
