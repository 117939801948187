import Color from 'color';
import {
  lFColor, lFColor0, lFColor2, mobileRes
} from 'pages/components/LFHeader/LFHeader.styled';
import { orange, topBarColor, topBarColor2 } from 'pages/styles';
import styled, { keyframes } from 'styled-components';
import { cashColor, redColor } from '../components/LogTable.styled';

export const MainContainer = styled.div`
  width: max-content;
  height: calc(30svh + 290px);
`;

export const DeleteTypeCont = styled.div`
  padding: 13px 20px;
  border-radius: 3px;
  width: 100px;
  height: 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-out 250ms;
  pointer-events: none;
`;

export const DTCont1 = styled.div`
  background: white;
  padding: 20px;
  pointer-events: auto;
  width: 95vw;
  max-width: 400px;
  border-radius: 3px;
  position: relative;
  opacity: 0;
  overflow: hidden;
  color: #525252;
  transition: all ease-out 250ms;
`;

export const DTHeader = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding-top: 5px;
  background: white;
  color: ${Color('#525252').darken(0.2).toString()};
`;

export const DTBranch = styled.div`
  display: inline-block;
  padding: 9px 18px;
  padding-top: 7px;
  font-weight: 500;
  background: ${Color(topBarColor).lighten(0.75).toString()};
  color: ${Color(topBarColor2).lighten(0.5).toString()};
  border-radius: 3px;
  margin-right: 10px;
  font-size: 14px;
  margin-top: 40px;
`;

export const DTLabel = styled.div`
  margin-top: 7px;
  font-size: 14px;
`;

export const DTNote = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: ${topBarColor2};
  padding: 12px 20px;
  border-radius: 3px;
  border: 1px solid ${topBarColor};
  width: max-content;
  letter-spacing: 0.25px;
`;

export const DTButtonsCont = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 30px;
`;

export const DTButton0 = styled.div`
  padding: 9px 14px;
  background: ${redColor};
  color: white;
  border-radius: 3px;
  font-weight: 700;
  font-size: 13px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    background: ${orange};
    color: white;
  }
`;

export const DTButton1 = styled(DTButton0)`
  padding: 9px 0px;
  background: none;
  color: ${topBarColor2};
  &:hover {
    background: none;
    color: ${orange};
  }
`;

const bgColor = Color('lightgrey').lighten(0.15).toString();

export const Container = styled.div`
  border-radius: 3px;
  color: ${Color('#525252').darken(0.2).toString()};
  width: 95vw;
  max-width: 385px;
  background: ${bgColor};
`;

const paddingLR = '15px';
const padding = '20px';

export const Header = styled.div`
  font-size: 20px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  font-weight: 700;
  background: white;
  padding: ${`${padding} ${paddingLR}`};
  padding-top: 10px;
`;

export const Header1 = styled.div`
  font-size: 13.5px;
  font-weight: 700;
  background: white;
  padding: ${`15px ${paddingLR}`};
  padding-bottom: 9px;
  margin-top: -1px;
  color: grey;
`;

export const BranchesSetterCont = styled.div`
  height: 0px;
  width: 100%;
  opacity: 0;
  background: ${bgColor};
  overflow: hidden;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
`;

export const Header2 = styled(Header1)`
  background: inherit;
  padding-top: 50px;
  padding-bottom: 0px;
  font-size: 12px;
  position: relative;
`;

export const Input = styled.input<{ isError: boolean }>`
  height: 50px;
  width: 100%;
  max-width: 220px;
  padding: 0px 7px;
  padding-top: 16px;
  margin-top: 6px;
  border-radius: 3px;
  margin-left: ${paddingLR};
  border-radius: 3px;
  border: 1px solid grey;
  border: ${({ isError }) => isError && '1px solid red'};
  &:focus {
    outline: none;
    border: 2px solid #525252;
    border-color: ${({ isError }) => isError && 'red'};
  }
`;

export const Info = styled.div<{ isError?: boolean }>`
  font-size: 11px;
  color: ${({ isError }) => isError ? 'red' : Color('lightgrey').darken(0.1).toString()};
  position: absolute;
  bottom: -25px;
  padding-left: 9px;
  pointer-events: none;
`;

export const ButtonsCont = styled.div`
  display: flex;
  margin-left: ${padding};
  gap: 15px;
  margin-top: 15px;
`;

export const Button0 = styled.div`
  padding: 9px 14px;
  background: ${Color(topBarColor).lighten(0.7).toString()};
  color: ${topBarColor2};
  border-radius: 3px;
  font-weight: 500;
  font-size: 13px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    background: ${orange};
    color: white;
  }
`;

export const Button1 = styled(Button0)`
  padding: 9px 5px;
  background: none;
  &:hover {
    background: none;
    color: ${orange};
  }
`;

export const BranchesCont0 = styled.div`
  position: relative;
  width: 100%;
`;

export const AddThisCont0 = styled.div`
  height: 187px;
  width: calc(100% + 2px);
  margin-left: -1px;
  background: ${Color(lFColor).darken(0.3).toString()};
  color: ${Color(lFColor).lighten(2.8).toString()};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const NoBranch = styled.div`
  position: absolute;
  right: 50%;
  bottom: 50%;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.25px;
  transform: translate(50%, 50%);
  border: 1px solid ${Color(lFColor2).lighten(2.8).toString()};
  border-radius: 3px;
  padding: 10px 16px; 
`;

const animation = (animate: string) => keyframes`
  0% {
    height: ${animate === '&' ? '182px' : '0px'};
    padding-top: ${animate === '&' ? '11px' : '0px'};
    padding-bottom: ${animate === '&' ? '15px' : '0px'};
  }
  100% {
    height: ${(animate && animate !== '&') ? '182px' : '0px'};
    padding-top: ${(animate && animate !== '&') ? '11px' : '0px'};
    padding-bottom: ${(animate && animate !== '&') ? '15px' : '0px'};
  }
`;

export const AddThisCont = styled.div<{ animate: string }>`
  padding: ${`0px ${paddingLR}`};
  background: ${lFColor};
  color: white;
  overflow: hidden;
  display: ${({ animate }) => !animate ? 'none' : 'block'};
  z-index: 2;
  animation:
    ${({ animate }) => animation(animate)}
    ease-in
    ${({ animate }) => animate === '&' ? '130ms' : '150ms'};
  -webkit-animation-fill-mode: forwards;
`;

export const HeaderPart = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Header3 = styled.div`
  font-size: 17px;
  font-weight: 600;
  width: max-content;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 77px);
`;

export const IconCont2 = styled.div`
  transform: translateY(-1px);
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${orange};
  }
  margin-left: auto;
  width: 20px;
`;

export const DetailsPart = styled.div`
  font-size: 12px;
  margin-top: 30px;
  height: 44px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
`;

export const Info01 = styled(Info)`
  font-size: 10px;
  color: ${({ isError }) => isError ? 'red' : Color('lightgrey').darken(0.3).toString()};
  bottom: 0px;
  top: 1px;
`;

export const Input0 = styled.input<{ isError: boolean }>`
  height: 100%;
  padding: 0px 8px;
  padding-top: 14px;
  border-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 0;
  flex: 1;
  border: ${({ isError }) => isError && '2px solid red'};
  font-weight: 700;
  font-size: 18px;
  min-width: 0;
  letter-spacing: 0.5px;
  &:focus {
    outline: none;
    border: 3px solid ${orange};
    border-color: ${({ isError }) => isError && 'red'};
  }
`;

export const Input01 = styled(Input0)`
  padding: 0px 6px;
  padding-top: 10px;
  font-weight: 500;
  font-size: 14px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  width: 65%;
  max-width: 200px;
`;

export const DatePart = styled.div`
  height: 100%;
  margin-left: 10px;
  transform: translateY(-15px);
`;

export const Label = styled.div`
  font-size: 12px;
  font-size: 500;
`;

export const Date = styled.div`
  font-size: 16px;
  margin-top: 3px;
  padding: 5.5px 11px;
  border-radius: 3px;
  font-weight: 500;
  border: 1px solid white;
  width: max-content;
  margin-left: auto;
`;

export const IconCont = styled.div`
  transform: translateY(6px);
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    color: ${orange};
  }
`;

export const AmountPart = styled.div`
  font-size: 12px;
  margin-top: 15px;
  height: 48px;
  display: flex;
  width: 100%;
  position: relative;
`;

export const Info1 = styled(Info)`
  font-size: 10.5px;
  color: ${({ isError }) => isError ? 'red' : Color('lightgrey').darken(0.3).toString()};
  bottom: 0px;
  top: 4px;
`;

export const Input1 = styled(Input0)<{ amount: number }>`
  color: ${({ amount }) => amount < 0 ? 'red' : cashColor};
  &:focus {
    border-color: ${({ isError }) => isError && 'red'};
  }
`;

export const Button = styled.div`
  font-size: 16px;
  height: 100%;
  display: flex;
  padding: 0px 18px;
  justify-content: center;
  align-items: center;
  width: max-content;
  font-weight: 700;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: ${Color(lFColor).lighten(0.5).toString()};
  &:hover {
    background: ${orange};
    cursor: pointer;
  }
`;

export const BranchesCont = styled.div`
  padding-left: 10px;
  height: max-content;
  max-height: 22svh;
  overflow: auto;
  position: relative;
  margin-top: 10px;
  z-index: 1;
`;

export const Branch = styled.div<{ isSelected?: boolean }>`
  display: inline-block;
  padding: 9px 18px;
  padding-top: 7px;
  font-weight: 500;
  background: ${({ isSelected }) => isSelected ? orange : Color(topBarColor).lighten(0.6).toString()};
  color: ${({ isSelected }) => isSelected ? Color(orange).darken(0.7).toString()
    : Color(topBarColor2).lighten(0.4).toString()};
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: ${orange};
    color: ${({ isSelected }) => !isSelected && Color(orange).darken(0.7).toString()};
  }
  @media(max-width: ${mobileRes}) {
    padding-top: 9px;
  }
`;

export const BottomCover = styled.div`
  width: 100%;
  height: 0px;
  overflow: visible;
  position: relative;
  z-index: 2;
  background: ${bgColor};
`;

export const BottomCover2 = styled.div`
  background: ${bgColor};
  width: calc(100% - 15px);
  height: 10px;
  position: absolute;
  top: -10px;
  left: 0px;
  border-bottom-left-radius: 3px;
`;

export const BranchesCont1 = styled(BranchesCont)`
  padding: 10px;
  box-shadow: 0px 0.5px 0.5px 0px rgba(0,0,0,0.2) inset;
  -webkit-box-shadow: 0px 0.5px 0.5px 0px rgba(0,0,0,0.2) inset;
  -moz-box-shadow: 0px 0.5px 0.5px 0px rgba(0,0,0,0.2) inset;
  margin: 0;
`;

export const Branch1 = styled(Branch)`
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  width: max-content;
`;

export const CalendarCont = styled.div<{ smallPad?: true }>`
  width: max-content;
  max-width: 95vw;
  border-radius: 3px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  background: white;
  margin-top: ${({ smallPad }) => smallPad && '25px'};
`;

export const CalendarLabel = styled.div<{ smallPad?: true }>`
  padding: ${({ smallPad }) => smallPad ? '5px 0px' : '11px 16px'};
  padding-top: ${({ smallPad }) => smallPad ? '0px' : '45px'};
  padding-bottom: ${({ smallPad }) => smallPad && '11px'};
  font-size: 17px;
  font-weight: 500;
`;

export const MetricsButton = styled.div`
  padding: 9px 16px;
  font-size: 13px;
  width: max-content;
  border-radius: 4px;
  background: ${Color(lFColor0).darken(0.1).toString()};
  color: white;
  font-weight: 500;
  height: max-content;
  cursor: pointer;
  margin-right: auto;
  transform: translateY(0px);
  &:hover {
    background: ${orange};
    color: ${Color(orange).darken(0.6).toString()};
  }
`;

export const MetricsButton1 = styled(MetricsButton)<{ smallPad?: true }>`
  transform: translateY(0px);
  background: ${Color(topBarColor2).lighten(2.45).toString()};
  color: ${Color(topBarColor2).lighten(0.8).toString()};
  margin-left: ${({ smallPad }) => smallPad ? '0px' : '15px'};
  margin-top: 16px;
`;
