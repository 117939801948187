import Color from 'color';
import { borderColor, orange } from 'pages/styles';
import styled from 'styled-components';

const highlightColor2 = orange;
const panelBorderColor = borderColor;
const textColor = '#525252';

const tableBorderColor = Color(panelBorderColor).lighten(0.1).toString();
export const TR = styled.tr<any>`
  border-top: .75px solid ${Color(tableBorderColor).darken(0.005).toString()};
  background: ${Color('white').darken(0.05).toString()};
`;

export const TD = styled.td<any>`
  color: ${Color(textColor).lighten(0.1).toString()};
  padding-right: ${(props) => { return props.isIndex ? '6px' : '9px'; }};
  padding-left: ${(props) => { return props.isIndex ? '23px' : '9px'; }};
  height: 23px;
  font-size: 11px;
  position: relative;
  max-width: 120px;
  // max-width: 10px;
  z-index: 2;
`;

export const TDText = styled.div`
  z-index: 2;
  height: 100%;
  position: relative;
  margin-top: 4px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Highlight2 = styled.div`
  position: absolute;
  right: 50%;
  bottom: 50%;
  height: 105%;
  width: 105%;
  z-index: 1;
  transform: translate(50%, 50%);
  background: ${highlightColor2};
`;
