import React, { useState } from 'react';
import * as S from './Subscribe.styled';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectHotelData } from 'pages/redux';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import Loader from 'pages/components/Loader/Loader';
import { toast } from 'react-toastify';
import { useHotel, useSocketEmit } from '../../Navigation/hooks';
import { extendSubscription } from '../redux';
import { getCost } from './Subscribe';
import { usePayment } from '../hooks';
import { selectRooms } from 'redux/global';

const ExtendSubscription = ({ onExit }:{ onExit: Function }) => {
  const rooms = useAppSelector(selectRooms);
  const isLoadingRooms = !rooms;
  const hotelData = useAppSelector(selectHotelData);
  const [isChannel, setIsChannel] = useState(!!hotelData?.channelExpiry
    && +new Date(hotelData.channelExpiry) > +new Date());
  const { updateHotel } = useHotel();
  const socketEmit = useSocketEmit();
  const dispatch = useAppDispatch();

  const dayDiff0 = new Date(hotelData?.expires || '').getTime() - new Date().getTime();
  const monthDiff0 = Math.ceil(12 - (dayDiff0 / (60 * 60 * 24 * 30 * 1000)));
  const monthDiff = monthDiff0 < 12 ? monthDiff0 < 0 ? 0 : monthDiff0 : 12;
  const [months, setMonths] = useState<number>(monthDiff);

  const totalCost0 = Math.ceil(
    (getCost(Number(hotelData?.maxRooms || 0), isChannel) * months) / 500
  ) * 500;
  const totalCost = months === 12 ? totalCost0 * (93 / 100) : totalCost0;

  const paymentDetails = {
    flutterDesc: `Extend subscription${isChannel ? ' with Channel Manager' : ''}`,
    flutterTitle: `Extension${isChannel ? ' with Channel Manager' : ''}`,
    totalCost,
    paymentDescription: `${months} months_${hotelData?.maxRooms} rooms${isChannel ? '_channel active' : ''}`
  };

  const extendSub = async (closePayment: () => void) => {
    const expires = +new Date(hotelData?.expires || 0) > +new Date() ?
      new Date(hotelData?.expires || 0) : new Date();
    expires.setMonth(expires.getMonth() + months);

    const billingDate = +new Date(hotelData?.expires || 0) < +new Date() ?
      new Date().toISOString() : hotelData?.billingDate || new Date().toISOString();

    let channelExpiry = hotelData?.channelExpiry;
    if (isChannel) {
      const cDate = new Date(hotelData?.channelExpiry || 0);
      if (!hotelData?.channelExpiry || +cDate < +new Date()) {
        channelExpiry = new Date(expires).toISOString();
      } else {
        cDate.setMonth(cDate.getMonth() + months);
        channelExpiry = cDate.toISOString();
      }
    }

    const res = await dispatch(extendSubscription({
      expires: expires.toISOString(),
      channelExpiry,
      billingDate
    }));

    if (res.status === 'success') {
      updateHotel({ expires: expires.toISOString(), channelExpiry, billingDate } as any);
      socketEmit('update_hotel', { hotelData: { expires: expires.toISOString(), channelExpiry, billingDate } });
      toast('Successfully extended subscription', { type: 'success' });
      closePayment();
      onExit();
    } else {
      toast(res.data, { type: 'error' });
      closePayment();
    }
  };

  const { isLoading, beginPayment } = usePayment({
    finalFunction: extendSub,
    onExit,
    paymentDetails
  });

  const onClickPay = () => {
    beginPayment();
  };

  return (
    <>
      {
        (isLoading || isLoadingRooms) ? <Loader isSticky skipSideBar /> : (
          <S.Container>
            <S.Header>EXTEND SUBSCRIPTION</S.Header>
            <EscapeButton onClick={() => onExit()} isRedBg isNoBg />
            <S.BottomPart>
              <S.FlexSet
                onClick={() => setIsChannel(!isChannel)}
              >
                <S.Label>
                  With
                  {' '}
                  <S.CM>Channel Manager</S.CM>
                </S.Label>
                <S.Select1
                  type="checkbox"
                  readOnly
                  checked={isChannel}
                />
              </S.FlexSet>
              <S.DurationFlex>
                <S.Duration>Billing cycle</S.Duration>
                <S.InputDiv1>
                  <S.Select
                    onChange={(e) => {
                      setMonths(
                        Number(e.target.value.replace(' months', '').replace(' month', ''))
                      );
                    }}
                  >
                    {
                      [...Array(monthDiff).keys()].map((diff) => (
                        <option key={`user_${diff}`} selected={diff === months - 1}>
                          {`${diff + 1} month${diff === 0 ? '' : 's'}`}
                        </option>
                      ))
                    }
                  </S.Select>
                </S.InputDiv1>
              </S.DurationFlex>
              {
                monthDiff === 12 ? (
                  <S.Info>
                    Get
                    <S.TenOff>7% off</S.TenOff>
                    with a 12 month cycle
                  </S.Info>
                ) : null
              }
              <S.Price>{`₦${totalCost.toLocaleString()}`}</S.Price>
              <S.SlashPrice>
                {(months === 12) ? <s>{`₦${totalCost0.toLocaleString()}`}</s> : null}
              </S.SlashPrice>
              <S.Button
                onClick={() => {
                  onClickPay();
                }}
                isDisabled={false}
              >
                Proceed to pay
              </S.Button>
            </S.BottomPart>
          </S.Container>
        )
      }
    </>
  );
};

export default ExtendSubscription;
