import { useCallback } from 'react';
import { convertDate23 } from 'utils';
import { useAppSelector } from 'redux/hooks';
import { useMetrics } from 'pages/Metrics/hooks';
import { selectMetricTime } from 'pages/redux';

export type PieChartLogData1 = {
  name: string,
  value: number,
  ind?: number,
}

export type OtherField = {
  name: string,
  value: number
}

export type PieChartLogData = {
  name: string,
  value: number,
  ind?: number,
  total: number,
  otherFields: OtherField[]
}

export type AreaChartData = {
  date: string,
  dateF: Date
  total: number,
  daily: number
}

export type SimpleLineData = Record<string, string | number | Date>;
export type SimpleLineChart = {
  chartData: SimpleLineData[],
  typeTotalValues: Record<string, number>
};

export const getDayEnd = (date: Date) => {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);
  return date;
};

// For daily area chart
export const getDayStart = (date: Date | number) => {
  date = new Date(date);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const useChartData = () => {
  const {
    checkFilteredLogs, logGroups, flowType, getFlowTypeValue, checkedMetric
  } = useMetrics();
  const metricTime = useAppSelector(selectMetricTime);

  const getSimpleLineData = useCallback((): SimpleLineChart => {
    const endDate = new Date(metricTime?.endDate || new Date());
    const startDate = new Date(metricTime?.startDate || new Date());

    const logTypeValues: Record<string, number> = {};
    checkedMetric.checked.forEach((logType) => {
      logTypeValues[logType] = 0;
    });

    type ChartDataBuild = {
      values: { [x: string]: number },
      date: Date
    }

    const chartDataBuild: ChartDataBuild[] = [];
    const typeTotalValues: Record<string, number> = {};

    const forceLogs = [
      {
        id: 'xxx2',
        type: '',
        message: '',
        date: getDayEnd(startDate).toString(),
        value: '0',
        updatedAsOf: '',
        count: 0,
      },
      {
        id: 'xxx1',
        type: '',
        message: '',
        date: getDayEnd(endDate).toString(),
        value: '0',
        updatedAsOf: '',
        count: 0,
      },
    ];

    const dates: Record<string, number> = {};
    [...checkFilteredLogs, ...forceLogs].forEach((l) => {
      dates[l.id] = +new Date(l.date);
    });

    [...checkFilteredLogs, ...forceLogs].sort((a, b) => +new Date(a.date) - +new Date(b.date))
      .forEach((log) => {
        chartDataBuild.sort((a, b) => +a.date - +b.date);
        const ind = chartDataBuild.findIndex((b) => dates[log.id] <= +b.date);
        if (log.type) {
          typeTotalValues[log.type] = (typeTotalValues[log.type] || 0)
            + getFlowTypeValue(log);
        }

        if (ind > -1) {
          if (log.type) {
            chartDataBuild[ind].values[log.type] += getFlowTypeValue(log);
          }
        } else {
          if (chartDataBuild.length > 0) {
            const tmp = [...chartDataBuild];
            const cDataBuild: ChartDataBuild = { ...tmp[chartDataBuild.length - 1] };
            const cDataBuildTotal = Object.entries(cDataBuild.values)
              .reduce((a, [k, v]) => a + (k === 'total' ? 0 : v), 0);

            chartDataBuild[chartDataBuild.length - 1] = {
              date: new Date(cDataBuild.date),
              values: {
                ...cDataBuild.values,
                total: cDataBuildTotal
              }
            };
          }

          let cDataBuild: ChartDataBuild = { date: new Date(), values: {} };
          if (chartDataBuild.length > 0) {
            const tmp = [...chartDataBuild];
            const values = { ...tmp[chartDataBuild.length - 1].values };
            delete values.total;

            cDataBuild = {
              ...tmp[chartDataBuild.length - 1],
              values
            };
          }

          const newChartBuild: ChartDataBuild = {
            date: getDayEnd(new Date(log.date)),
            values: chartDataBuild.length > 0 ?
              {
                ...cDataBuild.values,
                ...(log.type ? {
                  [log.type]: cDataBuild.values[log.type] + getFlowTypeValue(log)
                } : {})
              }
              :
              {
                ...logTypeValues,
                ...(log.type ? {
                  [log.type]: getFlowTypeValue(log)
                } : {})
              }
          };

          chartDataBuild.push(newChartBuild);
        }
      });

    if (chartDataBuild.length > 0) {
      chartDataBuild[chartDataBuild.length - 1] = {
        ...chartDataBuild[chartDataBuild.length - 1],
        values: {
          ...chartDataBuild[chartDataBuild.length - 1].values,
          total: Object.entries(chartDataBuild[chartDataBuild.length - 1].values)
            .reduce((a, [, v]) => a + v, 0)
        }
      };
    }

    const chartData: SimpleLineData[] = chartDataBuild.map((b) => {
      return {
        name: convertDate23(b.date),
        date: b.date,
        ...b.values
      };
    });

    Object.entries(typeTotalValues).forEach(([k, v]) => {
      if (!v) {
        chartData.forEach((d) => {
          delete d[k];
        });
        delete typeTotalValues[k];
      }
    });

    const max = 8;
    const keysSorted = Object.keys(typeTotalValues).sort(
      (a, b) => typeTotalValues[b] - typeTotalValues[a]
    );

    const initialLen = Object.keys(typeTotalValues).length - 1;
    let keys = keysSorted.filter(([,], i) => i < Math.ceil((max - 1) / 2)
      || initialLen - i < Math.trunc((max - 1) / 2));

    const othersCounts: number[] = [];
    if (keysSorted.length - keys.length >= 1) {
      chartData.forEach((d) => {
        let othersCount = 0;
        Object.entries(d).forEach(([k, v]) => {
          if (!keys.includes(k) && typeof v === 'number' && k !== 'total') {
            othersCount += v;
          }
        });
        othersCounts.push(othersCount);
      });
    } else {
      keys = keysSorted;
    }

    keys.push('total');

    Object.entries(typeTotalValues).forEach(([k]) => {
      if (!keys.includes(k)) {
        chartData.forEach((d, i) => {
          delete d[k];
          if (othersCounts.length) {
            d.Others = othersCounts[i];
          }
        });
        delete typeTotalValues[k];
      }
    });

    if (othersCounts.length) {
      // So others can be found
      typeTotalValues.Others = 0;
    }

    // console.log(chartData);
    // console.log(typeTotalValues, typeTotalValues);

    const keySorted = Object.keys(typeTotalValues).sort();
    const typeTotalValues1: typeof typeTotalValues = {};
    for (let i = 0; i < keySorted.length; i += 1) {
      typeTotalValues1[keySorted[i]] = typeTotalValues[keySorted[i]];
    }

    return { chartData, typeTotalValues: typeTotalValues1 };
  }, [checkFilteredLogs, metricTime, checkedMetric]);

  const getPieChartData = useCallback((charge?: '-' | '+') => {
    let total1 = 0;

    const chartData: PieChartLogData1[] = [];

    checkFilteredLogs.forEach((log) => {
      const ind = chartData.findIndex((d) => d.name === log.type);
      const value = getFlowTypeValue(log) < 0 ? getFlowTypeValue(log) * -1
        : getFlowTypeValue(log);
      const pushChartData = () => {
        total1 += value;
        if (ind > -1) {
          chartData[ind] = { ...chartData[ind], value: chartData[ind].value + value };
        } else {
          chartData.push({ name: log.type, value });
        }
      };
      if ((!charge || (charge === '-' && Number(log.value) < 0) || (charge === '+' && Number(log.value) >= 0))
        && value > 0) {
        pushChartData();
      }
    });

    const pCData0: PieChartLogData[] = chartData.map((d) => {
      return { ...d, total: total1, otherFields: [] };
    });

    const total0 = pCData0.reduce((a, b) => a + b.value, 0);
    const toRemove: string[] = [];

    pCData0.sort((a, b) => a.value - b.value);
    pCData0.forEach((d) => {
      if (Math.trunc((d.value / total0) * 100) < 9) {
        if (pCData0.length - toRemove.length > 7) {
          toRemove.push(d.name);
        }
      }
    });

    let pCData1: PieChartLogData[] = [];
    const others: PieChartLogData[] = [];

    if (toRemove.length > 1) {
      let othersTotal = 0;
      const otherFields: OtherField[] = [];
      toRemove.forEach((t) => {
        const thisValue = pCData0.find((d) => d.name === t)?.value || 0;
        pCData0.splice(pCData0.findIndex((d) => d.name === t), 1);
        othersTotal += thisValue;
        otherFields.push({ name: t, value: thisValue });
      });
      others.push({
        name: 'Others',
        value: othersTotal,
        total: total1,
        otherFields
      });
      pCData1 = [...pCData0];
    } else {
      pCData1 = [...pCData0];
    }

    const total = pCData1.reduce((a, b) => a + (Number(b.value) || 0), 0);
    const pCData11: PieChartLogData[] = total ? pCData1 : [];

    const all: PieChartLogData[] = [];
    Object.entries(logGroups).forEach(([, v]) => {
      const dTmp: PieChartLogData[] = [];
      pCData11.forEach((d) => {
        if (v.includes(d.name)) {
          dTmp.push(d);
        }
      });
      dTmp.sort((a, b) => {
        if (Number(a.value) < Number(b.value)) return 1;
        if (Number(a.value) > Number(b.value)) return -1;
        return 0;
      });
      if (dTmp.length) all.push(...dTmp);
    });

    const pCData22 = [...all];
    pCData22.sort((a, b) => {
      if (Number(a.value) < Number(b.value)) return 1;
      if (Number(a.value) > Number(b.value)) return -1;
      return 0;
    });

    pCData22.forEach((d, i) => {
      let currInd = -1;
      all.find((a, ind) => {
        currInd = ind;
        return a.name === d.name;
      });
      all[currInd].ind = i;
    });

    const pCData: PieChartLogData[] = total ? [...all, ...others] : [];
    pCData.sort((a, b) => a.name > b.name ? 1 : -1);

    return pCData;
  }, [checkFilteredLogs]);

  const getAreaChartData = useCallback((isUseRaw?: true) => {
    const endDate = new Date(metricTime?.endDate || new Date());
    const startDate = new Date(metricTime?.startDate || new Date());

    type AreaChartDataBuild = {
      date: number,
      total: number,
      daily: number
    }

    const areaChartBuild: AreaChartDataBuild[] = [];

    const forceLogs = [
      {
        id: 'xxx1',
        type: '',
        message: '',
        date: getDayEnd(startDate).toString(),
        value: '0',
        updatedAsOf: '',
        count: 0,
      },
      {
        id: 'xxx2',
        type: '',
        message: '',
        date: getDayEnd(endDate).toString(),
        value: '0',
        updatedAsOf: '',
        count: 0,
      },
    ];

    const dates: Record<string, number> = {};

    [...checkFilteredLogs, ...forceLogs].forEach((l) => {
      dates[l.id] = +new Date(l.date);
    });

    [...checkFilteredLogs, ...forceLogs].sort((a, b) => +new Date(a.date) - +new Date(b.date))
      .forEach((log) => {
        areaChartBuild.sort((a, b) => a.date - b.date);
        const ind = areaChartBuild.findIndex((d) => dates[log.id] <= d.date);
        const total0 = log.type ? getFlowTypeValue(log) : 0;
        const total = log.id ? total0 : 0;

        if (ind > -1) {
          areaChartBuild[ind] = {
            ...areaChartBuild[ind],
            total: areaChartBuild[ind].total + total,
            daily: areaChartBuild[ind].daily + total
          };
        } else {
          const priorTotal = areaChartBuild.length > 0 ? areaChartBuild[areaChartBuild.length - 1]
            .total : 0;

          areaChartBuild.push({
            date: +getDayEnd(new Date(log.date)),
            total: total + priorTotal,
            daily: total
          });
        }
      });

    const areaChartData: AreaChartData[] = areaChartBuild.map((d) => {
      return {
        date: isUseRaw ? new Date(d.date || 0).toISOString() : convertDate23(new Date(d.date)),
        dateF: new Date(d.date || 0),
        total: d.total,
        daily: d.daily
      };
    });

    return areaChartData;
  }, [checkFilteredLogs, metricTime, flowType]);

  return { getPieChartData, getAreaChartData, getSimpleLineData };
};
