import React, { useState } from 'react';
import * as S from './Subscribe.styled';
import * as X from './Upgrade.styled';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectHotelData } from 'pages/redux';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import Loader from 'pages/components/Loader/Loader';
import { toast } from 'react-toastify';
import { useHotel, useSocketEmit } from '../../Navigation/hooks';
import { upgrade } from '../redux';
import { getCost } from './Subscribe';
import moment from 'moment';
import { usePayment } from '../hooks';

export const getDayDifferenceText = (startD: Date, endD: Date) => {
  const start = moment(startD, 'M/D/YYYY');
  const end = moment(endD, 'M/D/YYYY');
  const diff0 = moment.duration(end.diff(start));
  const days = (diff0.days() + 1) >= 29 ? 0 : diff0.days() + 1;
  const monthsTmp = days === 0 ? diff0.months() + 1 : diff0.months();
  const years = (monthsTmp === 12) ? diff0.years() + 1 : diff0.years();
  const months = (monthsTmp < 12) ? monthsTmp : 0;
  const result = `${years ? ` ${years} year${years === 1 ? '' : 's'}` : ''}
    ${months ? `${months} month${months === 1 ? '' : 's'}` : ''}
    ${days ? `${days} day${days === 1 ? '' : 's'}` : ''}`;
  return result;
};

const Upgrade = ({ onExit }:{ onExit: Function }) => {
  const hotelData = useAppSelector(selectHotelData);
  const maxRooms = Number(hotelData?.maxRooms || 0);
  const [roomCount, setRoomCount] = useState<number>(maxRooms);
  const [countError, setCountError] = useState<string>('');
  const { updateHotel } = useHotel();
  const socketEmit = useSocketEmit();
  const dispatch = useAppDispatch();

  if (!maxRooms) onExit();

  const start = moment(new Date(), 'M/D/YYYY');
  const end = moment(new Date(hotelData?.expires || 0), 'M/D/YYYY');
  const diff0 = moment.duration(end.diff(start));
  const daysLeft = diff0.asDays();

  const oldRoomsCost = daysLeft * (getCost(Number(hotelData?.maxRooms || 0)) / 30);
  const newRoomsCost = daysLeft * (getCost(roomCount) / 30);

  const totalCost00 = newRoomsCost - oldRoomsCost > 0 ? newRoomsCost - oldRoomsCost : 0;
  const totalCost = (Math.ceil(totalCost00 / 50) * 50) > 500 ||
    roomCount <= Number(hotelData?.maxRooms || 0) ? (Math.ceil(totalCost00 / 50) * 50) : 500;

  const paymentDetails = {
    flutterDesc: 'Upgrade rooms payment',
    flutterTitle: 'Upgrade rooms',
    totalCost,
    paymentDescription: `${maxRooms} rooms to ${roomCount}`
  };

  const upgradeCall = async (closePayment: () => void) => {
    const res = await dispatch(upgrade(roomCount.toString()));

    if (res.status === 'success') {
      updateHotel({ maxRooms: roomCount.toString() } as any);
      socketEmit('update_hotel', { hotelData: { maxRooms: roomCount.toString() } });
      toast(`Successfully upgraded rooms from ${maxRooms} to ${roomCount}`, { type: 'success' });
      closePayment();
      onExit();
    } else {
      toast(res.data, { type: 'error' });
      closePayment();
    }
  };

  const { isLoading, beginPayment } = usePayment({
    finalFunction: upgradeCall,
    onExit,
    paymentDetails
  });

  const onClickPay = () => {
    setCountError('');
    if (!roomCount) {
      setCountError('Room amount is needed');
      return;
    }
    if (roomCount < maxRooms) {
      setCountError(`Cannot upgrade to rooms lower than your current ${maxRooms} rooms.`);
      return;
    }
    if (roomCount === maxRooms) {
      setCountError('No changes');
      return;
    }

    beginPayment();
  };

  return (
    <>
      {
        isLoading ? <Loader isSticky skipSideBar /> : (
          <S.Container>
            <X.Header>UPGRADE ROOMS</X.Header>
            <EscapeButton onClick={() => onExit()} isNoBorder isNoBg />
            <S.BottomPart>
              <S.RoomsFlex>
                <S.Rooms>Total Rooms</S.Rooms>
                <S.InputDiv>
                  <S.Input
                    type="number"
                    isError={!!countError}
                    value={roomCount.toString()}
                    onChange={(e) => {
                      setCountError('');
                      if (e.target.value.includes('e') || e.target.value.includes('-')) {
                        return;
                      }
                      setRoomCount(Number(e.target.value));
                    }}
                  />
                </S.InputDiv>
              </S.RoomsFlex>
              {countError ? <S.Error>{countError}</S.Error> : null}
              <X.Info>
                The room upgrade will be added to your current
                {' '}
                {getDayDifferenceText(new Date(), new Date(hotelData?.expires || 0)).trim()}
                {' '}
                subscription.
              </X.Info>
              <S.Price>
                {
                  roomCount <= 10000 ? <>{`₦${totalCost.toLocaleString()}`}</> : (
                    <>
                      <S.ContactUs>Contact us</S.ContactUs>
                      <S.Email>ceo@lodgefirst.com</S.Email>
                    </>
                  )
                }
              </S.Price>
              <S.Button
                onClick={() => {
                  if (roomCount <= 10000) onClickPay();
                }}
                isDisabled={roomCount > 10000}
              >
                Proceed to pay
              </S.Button>
            </S.BottomPart>
          </S.Container>
        )
      }
    </>
  );
};

export default Upgrade;
