import Color from 'color';
import {
  borderColor,
  borderColor2,
  logRes1,
  mobileRes2,
  topBarColor2,
} from 'pages/styles';
import styled from 'styled-components';

export const scrollBarRes = '1150px';
const lFColor = '#490c7b';
const lFColor2 = '#212137';
const orange = Color('#d6a615').darken(0).toString();

export const AuditsCont = styled.div<{ isWeb?: boolean }>`
  width: max-content;
  background: white;
  flex: 0 1;
  display: flex;
  flex-direction: column;
  @media(max-width: ${logRes1}) {
    position: ${({ isWeb }) => isWeb && 'fixed'};
    left: ${({ isWeb }) => isWeb && '-100%'};
  }
`;

const padding = '50px';
const padding2 = '32px';

export const SearchCont = styled.div`
  width: 100%;
  max-width: 220px;
  min-height: 50px;
  padding: 25px;
  padding-bottom: 18px;
  position: relative;
  @media(max-width: ${mobileRes2}) {
    max-width: 180px;
  }
`;

export const IconDiv = styled.div<{ isActive: boolean }>`
  position: absolute;
  top: 34px;
  left: 35px;
  color: grey;
  color: ${({ isActive }) => isActive && 'white'};
`;

export const Input = styled.input<{ isActive: boolean }>`
  width: 100%;
  height: 40px;
  padding-left: 40px;
  padding-right: 9px;
  border-radius: 3px;
  border: 1px solid ${borderColor2};
  outline: 0;
  font-size: 12px;
  color: black;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: ${({ isActive }) => isActive ? 600 : 300};
  background: ${({ isActive }) => isActive && lFColor2};
  color: ${({ isActive }) => isActive && 'white'};
  font-size: ${({ isActive }) => isActive && '14px'};
`;

export const Header = styled.div`
  width: max-content;
  font-weight: 700;
  font-size: 15px;
  padding-bottom: 15px;
  color: ${topBarColor2};
  text-align: right;
  margin-left: 25px;
  @media(max-width: ${scrollBarRes}) {
    padding-right: ${padding};
  }
  @media(max-width: ${logRes1}) {
    display: flex;
    align-items: flex-end;
  }
  @media(max-width: ${mobileRes2}) {
    padding-right: calc(${padding2});
  }
`;

export const Audits = styled.div`
  flex: 1;
  padding: 0px ${padding};
  padding-left: calc(${padding} + 3px);
  padding-top: 42px;
  color: ${borderColor};
  overflow-y: auto;
  position: relative;
  margin-bottom: 30px;
  border-top: 1px solid ${Color('lightgrey').lighten(0.03).toString()};
  border-bottom: 1px solid ${Color('lightgrey').lighten(0.03).toString()};
  @media(max-width: ${mobileRes2}) {
    padding: 40px ${padding2};
  }
`;

export const AuditCont = styled.div`
  width: 100%;
  padding-bottom: 37px;
`;

export const AuditGroup1 = styled.div`
  width: max-content;
  margin-left: auto;
  font-weight: 600;
  font-size: 14px;
  color: ${topBarColor2};
  padding-bottom: 10px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  &:hover {
    color: ${Color('#1685ec').darken(0.2).toString()};
  }
  @media(max-width: ${mobileRes2}) {
    font-size: 13px;
  }
`;

export const AuditGroup0 = styled(AuditGroup1)`
  padding: 9px 15px;
  color: white;
  font-weight: 700;
  background: ${Color(lFColor).lighten(0.2).toString()};
  border-radius: 3px;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 12px;
  &:hover {
    color: white;
    background: ${Color(orange).lighten(0.2).toString()};
  }
`;

export const AuditGroup = styled(AuditGroup1)`
  padding-bottom: 13px;
`;

export const Select = styled.input`
  height: 19px;
  width: 19px;
  margin: 0;
  margin-left: 12px;
  pointer-events: none;
`;

export const Select2 = styled(Select)`
  height: 15.5px;
  width: 15.5px;
`;

export const AuditName = styled.div`
  width: max-content;
  margin-left: auto;
  font-size: 12px;
  color: #525252;
  padding: 15px 0 13px 0;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  &:hover {
    color: ${Color('#1685ec').darken(0.2).toString()};
  }
  @media(max-width: ${mobileRes2}) {
    font-size: 12px;
  }
`;

export const EsacpePart = styled.div`
  display: none;
  width: 100%;
  height: 0px;
  overflow: visible;
  position: absolute;
  top: 0;
  right: 0;
  @media(max-width: ${logRes1}) {
    display: block;
  }
`;
