import Color from 'color';
import { lightPurple, logSmallRes, redColor } from 'pages/Logs/components/LogTable.styled';
import { lFColor, orange } from 'pages/components/LFHeader/LFHeader.styled';
import styled from 'styled-components';

export const Container = styled.div`
  width: 95vw;
  max-width: 420px;
  background: white;
  border-radius: 7px;
`;

export const Header = styled.div`
  background: #6F23CF;
  color: white;
  width: 100%;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  padding: 15px 40px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
`;

export const HeaderCM = styled(Header)`
  background: ${orange};
  color: ${Color(orange).darken(0.7).toString()};
  border-bottom: 1px solid ${Color(orange).darken(0.3).toString()};
`;

export const BottomPart = styled.div`
  background: white;
  padding-top: 18px;
  padding-bottom: 40px;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  width: 100%;
`;

export const RoomsFlex = styled.div`
  margin: auto;
  width: calc(100% - 20px);
  max-width: 270px;
  height: 45px;
  display: flex;
`;

export const Rooms = styled.div`
  background: ${lFColor};
  width: 140px;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  padding-right: 12px;
  color: white;
  font-weight: 600;
  font-size: 15px;
`;

export const InputDiv = styled.div`
  flex: 1;
`;

export const Input = styled.input<{ isError?: boolean }>`
  height: 100%;
  width: 100%;
  border-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid grey;
  border-left: none;
  padding-left: 9px;
  font-size: 19px;
  font-weight: 500;
  font-family: Poppins;
  color:${Color('#525252').darken(0.15).toString()};
  border-color: ${({ isError }) => isError && 'red'};
  border-width: ${({ isError }) => isError && '1.5px'};
  color: ${({ isError }) => isError && 'red'};
  &:focus {
    outline: none;
    border-color: ${({ isError }) => isError && 'red'};
  }
`;

export const Error = styled.div`
  padding: 10px 20px;
  border: 1px solid ${redColor};
  max-width: 200px;
  color: ${redColor};
  border-radius: 3px;
  margin: auto;
  margin-top: 10px;
  font-size: 12px;
  width: max-content;
`;

export const FlexSet = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  color: #525252;
  font-size: 14px;
  gap: 5px;
  padding: 12px 14px;
  border-radius: 3px;
  background: ${Color('lightgrey').lighten(0.1).toString()};
  cursor: pointer;
  width: max-content;
  margin: auto;
  margin-top: 15px;
  @media(max-width: ${logSmallRes}) {
    font-size: 11px;
  }
  &:hover {
    background: ${Color('lightgrey').lighten(0.07).toString()};
  }
`;

export const Label = styled.div`
`;

export const CM = styled.div`
  font-weight: 600;
  display: inline-block;
`;

export const Select1 = styled.input`
  height: 20px;
  width: 20px;
  margin: 0;
  margin-bottom: 0px;
  pointer-events: none;
`;

export const DurationFlex = styled(RoomsFlex)`
  margin-top: 45px;
`;

export const Info = styled.div`
  font-size: 12px;
  margin: auto;
  margin-top: 15px;
  width: max-content;
  color: ${Color('grey').darken(0.1).toString()};
  font-weight: 500;
`;

export const TenOff = styled.span`
  color: ${Color(orange).darken(0.4).toString()};
  background: ${Color(orange).lighten(0.3).toString()};
  border-radius: 3px;
  margin: 0px 3px;
  padding: 2px 5px;
  border: 1px solid ${Color(orange).lighten(0.2).toString()};
  font-weight: 600;
  font-size: 13px;
`;

export const Duration = styled(Rooms)`
  background: ${Color(lightPurple).darken(0.05).toString()};
  color: ${Color(lightPurple).darken(0.6).toString()};
  border: 1px solid ${Color(lightPurple).darken(0.2).toString()};
`;

export const InputDiv1 = styled.div`
  flex: 1;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid grey;
  border-left: none;
`;

export const Select = styled.select`
  height: 100%;
  width: 100%;
  padding-left: 7px;
  border: none;
  font-size: 15px;
  font-weight: 600;
  font-family: Poppins;
  color:${Color('#525252').darken(0.15).toString()};
  cursor: pointer;
`;

export const Price = styled.div`
  font-size: 38px;
  width: max-content;
  color: ${Color('#525252').darken(0.4).toString()};
  margin: auto;
  font-weight: 700;
  margin-top: 45px;
`;

export const SlashPrice = styled(Price)`
  font-size: 17px;
  color: ${Color('#525252').lighten(0.4).toString()};
  font-weight: 500;
  margin-top: -10px;
  height: 30px;
`;

export const PriceInfo = styled.div`
  font-size: 12px;
  color: ${Color('grey').lighten(0).toString()};
  font-weight: 400;
  margin-top: -15px;
  width: max-content;
  margin: auto;
`;

export const ContactUs = styled.div`
  font-size: 13px;
  width: max-content;
  margin: auto;
  margin-top: 45px;
  font-weight: 500;
`;

export const Email = styled.div`
  font-size: 16px;
  width: max-content;
  margin: auto;
  font-weight: 600;
`;

export const Button = styled.div<{ isDisabled: boolean }>`
  padding: 10px 50px;
  width: max-content;
  color: white;
  background: #6F23CF;
  border-radius: 7px;
  margin: auto;
  font-size: 15px;
  font-weight: 600;
  margin-top: 52px;
  opacity: ${({ isDisabled }) => isDisabled && '0.2'};
  &:hover {
    background:  ${({ isDisabled }) => !isDisabled && orange};
    cursor: ${({ isDisabled }) => !isDisabled && 'pointer'};
  }
`;
