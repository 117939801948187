import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import Loader from 'pages/components/Loader/Loader';
import { useAppSelector } from 'redux/hooks';
import { selectToken } from 'redux/global';
import Layout from 'pages/Layout/Layout';

const Homepage = lazy(() => import('pages/Homepage/Homepage'));
const Logs = lazy(() => import('pages/Logs/Logs'));
const Settings = lazy(() => import('pages/Settings/Settings'));
const Forgot = lazy(() => import('pages/Forgot/Forgot'));
const SetPassword = lazy(() => import('pages/SetPassword/SetPassword'));
const Insights = lazy(() => import('pages/Insights/Insights'));
const SignIn = lazy(() => import('pages/SignIn/SignIn'));

(document.body.style as any).zoom = '100.06%';

const Routing = function Routing() {
  const token = useAppSelector(selectToken);

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Homepage />
            </Route>
            <Route exact path="/forgot">
              <Forgot />
            </Route>
            <Route exact path="/forgot/:key/:id">
              <SetPassword />
            </Route>
            <Route exact path="/signin">
              <SignIn />
            </Route>
            {
              token && (
                <Route exact path={['/settings/editroom/:id', '/settings', '/dashboard', '/logs', '/logs/:action']}>
                  <Route exact path="/settings/editroom/:id">
                    <Settings />
                  </Route>
                  <Route exact path="/settings">
                    <Settings />
                  </Route>
                  <Route exact path="/logs">
                    <Logs />
                  </Route>
                  <Route exact path="/logs/:action">
                    <Logs />
                  </Route>
                  <Route exact path="/dashboard">
                    <Insights />
                  </Route>
                </Route>
              )
            }
          </Switch>
        </Layout>
      </Suspense>
    </Router>
  );
};

export default Routing;
