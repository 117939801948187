import { redColor } from 'pages/Logs/components/LogTable.styled';
import { orange } from 'pages/styles';
import styled from 'styled-components';

const tableAddedRes1 = '538px';

export const Container = styled.div`
  padding-bottom: 0px;
  position: relative;
  background: white;
  height: max-content;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 14px);
  max-width: 630px;
  z-index: 5;
  border-radius: 2px;
  @media(max-width: ${tableAddedRes1}) {
    max-height: calc(100svh - 14px);
    max-width: 300px;
  }
`;

export const RowsContainer = styled.div`
  margin-left: 12px;
  max-height: 90%;
  overflow-y: auto;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const TableCont = styled.div`
  display: flex;
  justify-content: center;
`;

export const Table = styled.div`
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  // flex: 1;
  width: max-content;
  cursor: pointer;
  &:hover {
    color: ${orange};
  }
`;

export const IconCont = styled.div`
  cursor: pointer;
  color: #525252;
  width: max-content;
  &:hover {
    color: ${orange};
  }
`;

export const IconCont1 = styled.div`
  width: 0px;
  margin-left: auto;
  overflow: hidden;
  text-align: right;
  transition: all ease-out 150ms;
  color: ${redColor};
  cursor: pointer;
`;
