import Color from 'color';
import { lightPurple } from 'pages/Logs/components/LogTable.styled';
import { lFColor } from 'pages/components/LFHeader/LFHeader.styled';
import styled from 'styled-components';

export const Header = styled.div`
  border-bottom: 1px solid ${lFColor};
  color: ${lFColor};
  background: ${lightPurple};
  width: 100%;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  padding: 15px 40px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
`;

export const Info = styled.div`
  color: ${Color('lightgrey').darken(0.275).toString()};
  font-size: 12px;
  max-width: 250px;
  margin: auto;
  margin-top: 25px;
  text-align: center;
`;
