import React, { useState } from 'react';
import * as S from './LoggedInModal.styled';
import { useAppDispatch } from 'redux/hooks';
import Loader from 'pages/components/Loader/Loader';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import { toast } from 'react-toastify';
import {
  setIsStaySignedIn, setPassword, setUserEmail, useCreds
} from 'redux/global';

const LoggedInModal = function LoggedInModal(
  { onExit, extraFunction }:{ onExit: Function, extraFunction?: Function }
) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { logout } = useCreds();

  return (
    <>
      { loading ? <Loader />
        : (
          <S.Container>
            <EscapeButton onClick={() => onExit()} />
            <S.MainLabel>Logged in</S.MainLabel>
            <S.Button1>
              <S.Button
                onClick={async () => {
                  setLoading(true);
                  dispatch(logout());
                  dispatch(setIsStaySignedIn(false));
                  dispatch(setUserEmail(''));
                  dispatch(setPassword(''));
                  toast('Logged out successfully!', { type: 'success' });
                  extraFunction?.();
                  onExit();
                  setLoading(false);
                }}
              >
                Log out
              </S.Button>
            </S.Button1>
          </S.Container>
        )}
    </>
  );
};

export default LoggedInModal;
