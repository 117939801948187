import { lFColor } from 'pages/components/LFHeader/LFHeader.styled';
import { orange, topBarColor2 } from 'pages/styles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 90vw;
  height: max-content;
  max-width: 300px;
  padding: 20px;
  background: white;
  border-radius: 3px;
  color: black;
`;

export const MainLabel0 = styled.div`
  font-size: 19px;
  color: ${topBarColor2};
  margin-bottom: 15px;
  font-weight: 700;
`;

export const Label = styled.div`
  font-size: 14px;
`;

export const Input = styled.input<{ error?: boolean }>`
  font-size: 13px;
  padding-left: 7px;
  margin-top: 5px;
  height: 35px;
  width: 100%;
  color: black;
  font-family: Poppins;
  border: ${({ error }) => error && '1px solid red'};
  &:focus {
    outline: none;
    border-color: ${({ error }) => error && 'red'};
  }
`;

export const Error = styled.div`
  font-size: 11px;
  margin-top: 3px;
  margin-bottom: 5px;
  color: red;
`;

export const Label2 = styled.div`
  margin-top: 10px;
  font-size: 14px;
`;

export const InputDiv = styled.div`
  height: max-content;
  width: 100%;
  position: relative;
`;

export const Visibility = styled.div`
  height: 100%;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 2px;
  cursor: pointer;
`;

export const StaySignedArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  gap: 5px;
`;

export const Input1 = styled.input`
  height: 21px;
  width: 21px;
  cursor: pointer;
  margin: 0;
`;

export const StaySigned = styled.div`
  font-size: 14px;
`;

export const ForgortSect = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 7px;
  color: #1685ec;
  font-size: 14px;
  margin-top: 40px;
`;

export const ForgortPassword = styled.div`
  font-size: 13px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const StaffRegister = styled(ForgortPassword)`
`;

export const Button1 = styled.div`
  color: #1685ec;
  margin-top: 50px;
  width: max-content;
  display: flex;
  gap: 15px;
`;

export const Info = styled.div`
  font-size: 13px;
  margin: auto;
  font-family: Poppins;
`;

export const Select = styled.select<{ isError: boolean }>`
  height: 45px;
  width: 100%;
  margin: auto;
  margin-top: 5px;
  padding-left: 7px;
  cursor: pointer;
  font-family: Poppins;
  border: ${({ isError }) => isError && '1px solid red'};
`;

export const ButtonSect = styled.div`
  width: max-content;
  display: flex;
  gap: 15px;
  margin-top: 75px;
`;

export const Button0 = styled.div`
  padding: 7px 15px;
  background: white;
  color: ${lFColor};
  cursor: pointer;
  border-radius: 3px;
  &:hover {
    background: ${orange};
  }
`;

export const Button = styled(Button0)`
  background: ${lFColor};
  color: white;
`;
