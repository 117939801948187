import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as S from './SignInModal.styled';
import Loader from 'pages/components/Loader/Loader';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectIsStaySignedIn,
  selectPassword, selectUserEmail, useCreds, setIsStaySignedIn as setSavedIsStaySignedIn,
  setUserEmail,
  setPassword as setSavedPassword
} from 'redux/global';
import { isValidatedEmail } from 'utils';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { getLogInBranches, setIsStaff } from 'pages/Homepage/redux';
import { selectHotelData, setHotelData } from 'pages/redux';
import { getHotel } from 'pages/Navigation/redux';
import { usePath } from 'pages/hooks';

export type HotelsData = {
  name: string,
  id: string,
  permission: number
}

const SignInModal = ({
  onExit,
  setIsLoading
}:{
  onExit: Function,
  setIsLoading?: Function,
}) => {
  const savedIsStaySignedIn = useAppSelector(selectIsStaySignedIn);
  const savedEmail = useAppSelector(selectUserEmail);
  const savedPassword = useAppSelector(selectPassword);
  const [isStaySignedIn, setIsStaySignedIn] = useState<boolean>(savedIsStaySignedIn);
  const [email, setEmail] = useState<string>(isStaySignedIn ? savedEmail : '');
  const [password, setPassword] = useState<string>(isStaySignedIn ? savedPassword : '');
  const [passType, setPassType] = useState<string>('password');
  const [loading, setLoading1] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>();
  const [step, setStep] = useState<number>(1);
  const [hotelsData, setHotelsData] = useState<HotelsData[]>();
  const [selectedHotelId, setSelectedHotelId] = useState<string>();
  const [isSelectHotelError, setIsSelectHotelError] = useState<boolean>(false);
  const hotelData = useAppSelector(selectHotelData);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { logout, login } = useCreds();
  const { navigateToHome } = usePath();

  const setLoading = (bool: boolean) => {
    setIsLoading?.(bool);
    setLoading1(bool);
  };

  const finalLogIn = async (sId?: string) => {
    if ((hotelsData?.find((d) => d.id === selectedHotelId)?.permission || 0) <= 2) {
      toast('You dont have permission', { type: 'error' });
      return;
    }

    setLoading(true);
    dispatch(logout());

    const hotelId = await dispatch(
      login({ email: email.toLowerCase(), password, hotelId: sId || selectedHotelId })
    );
    if (hotelId) {
      const res = await dispatch(getHotel(hotelId));
      setLoading(false);
      if (res.status === 'success') {
        dispatch(setHotelData(res.data[0]));
        dispatch(setSavedIsStaySignedIn(isStaySignedIn));
        dispatch(setUserEmail(email.toLowerCase()));
        if (isStaySignedIn) {
          dispatch(setSavedPassword(password));
        } else {
          dispatch(setSavedPassword(''));
        }
        dispatch(setIsStaff(true));
        toast('Succesfully logged in', { type: 'success' });
        return;
      }
      toast(res.data, { type: 'error' });
      return;
    }
    setLoading(false);
  };

  const onClickLogIn0 = async () => {
    setLoading(true);

    const res = await dispatch(getLogInBranches({ email: email.toLowerCase(), password }));

    if (res.status === 'success') {
      if (res.data.length === 1) {
        if (res.data[0].permission <= 2) {
          toast('You dont have permission', { type: 'error' });
          setLoading(false);
        } else {
          finalLogIn(res.data[0]);
        }
      } else {
        setHotelsData(res.data);
        setLoading(false);
        setStep(2);
      }
      return;
    }
    toast(res.data, { type: 'error' });
    setLoading(false);
  };

  useEffect(() => {
    if (Object.keys(hotelData || {}).length) {
      navigateToHome();
      onExit();
    }
  }, [hotelData]);

  const Step1 = () => {
    return (
      <>
        <form>
          <S.Label>Email</S.Label>
          <S.Input
            value={email}
            error={Boolean(emailError)}
            onChange={(e) => {
              setEmail(e.target.value.toLowerCase());
              if (e.target.value.toLowerCase() === '' || isValidatedEmail(e.target.value.toLowerCase())) {
                setEmailError('');
              } else {
                setEmailError('Email is invalid!');
              }
            }}
          />
          { emailError && <S.Error>{emailError}</S.Error>}
          <S.Label2>Password</S.Label2>
          <S.InputDiv>
            <S.Input
              value={password}
              type={passType}
              onChange={(e) => setPassword(e.target.value)}
            />
            <S.Visibility
              onClick={() => {
                if (passType === 'password') {
                  setPassType('');
                  return;
                }
                setPassType('password');
              }}
            >
              {
                passType === 'password' ? <Visibility fontSize="medium" /> : <VisibilityOff fontSize="medium" />
              }
            </S.Visibility>
          </S.InputDiv>
        </form>
        <S.StaySignedArea>
          <S.Input1
            type="checkbox"
            checked={isStaySignedIn}
            onChange={() => setIsStaySignedIn(!isStaySignedIn)}
          />
          <S.StaySigned>Stay signed in</S.StaySigned>
        </S.StaySignedArea>
        <S.ForgortSect>
          <S.ForgortPassword
            onClick={() => {
              history.push('/forgot');
              onExit();
            }}
          >
            Forgot password
          </S.ForgortPassword>
        </S.ForgortSect>
        <S.Button1>
          <Button
            variant="outlined"
            color="inherit"
            sx={{ borderWidth: '1px' }}
            onClick={async () => {
              history.push('/');
            }}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            sx={{ borderWidth: '1px' }}
            onClick={() => onClickLogIn0()}
          >
            Log in
          </Button>
        </S.Button1>
      </>
    );
  };

  const onClickStart = () => {
    setIsSelectHotelError(false);
    if (!selectedHotelId) {
      setIsSelectHotelError(true);
      toast('Please select branch', { type: 'warning' });
      return;
    }
    finalLogIn();
  };

  const Step2 = () => {
    return (
      <>
        <S.Info>
          Please, select Hotel branch
        </S.Info>
        <S.Select
          onChange={(e) => {
            setIsSelectHotelError(false);
            const thisHotelData = hotelsData?.find((d) => d.name === e.target.value);
            if (thisHotelData) {
              setSelectedHotelId(thisHotelData.id);
            }
          }}
          isError={isSelectHotelError}
        >
          <option disabled selected>Select Hotel Branch</option>
          {
            hotelsData?.map((d) => (
              <option key={`hData_${d.name}_${d.id}`} selected={selectedHotelId === d.id}>
                {d.name}
              </option>
            ))
          }
        </S.Select>
        <S.ButtonSect>
          <S.Button0
            onClick={() => {
              setSelectedHotelId('');
              setIsSelectHotelError(false);
              setStep(1);
            }}
          >
            Back
          </S.Button0>
          <S.Button onClick={() => onClickStart()}>
            Next
          </S.Button>
        </S.ButtonSect>
      </>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('main_cont')?.scrollTo({ top: 0, behavior: 'smooth' });
    }, 140);
  }, [step]);

  return (
    <>
      { loading ? <Loader isSticky />
        : (
          <S.Container>
            <S.MainLabel0>Sign in</S.MainLabel0>
            {
              step === 1 ? <>{Step1()}</> : <>{Step2()}</>
            }
          </S.Container>
        )}
    </>
  );
};

export default SignInModal;
