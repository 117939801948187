import React, { useState } from 'react';
import * as S from './LFHeader.styled';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectToken } from 'redux/global';
import { useHistory } from 'react-router-dom';
import Menu from '@mui/icons-material/Menu';
import { moveSideBar } from 'pages/Navigation/utils';
import { mobileRes2 } from 'pages/styles';
import { selectHotelData, setHotelData } from 'pages/redux';
import Modal from '../Modal/Modal';
import LoggedInModal from './modals/LoggedInModal';
import { usePath } from 'pages/hooks';

export const LodgeFirst = () => {
  const { isHomePath } = usePath();

  return (
    <S.LodgeFirstCont>
      <S.LodgePart>
        <S.Lodge isHome={isHomePath}>
          Lodge
        </S.Lodge>
        {' '}
        <S.First isHome={isHomePath}>
          1st
        </S.First>
      </S.LodgePart>
    </S.LodgeFirstCont>
  );
};

const LFHeader = () => {
  const [isShowLogOut, setIsShowLogOut] = useState<boolean>(false);
  const token = useAppSelector(selectToken);
  const hotelData = useAppSelector(selectHotelData);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isHomePath } = usePath();

  return (
    <>
      {
        isShowLogOut && (
          <Modal
            component={(
              <LoggedInModal
                extraFunction={() => {
                  dispatch(setHotelData(null));
                  history.push('/');
                }}
                onExit={() => {
                  setIsShowLogOut(false);
                }}
              />
            )}
            exitOnBgClick={() => setIsShowLogOut(false)}
          />
        )
      }
      <S.Container isHome={isHomePath}>
        <S.Menu>
          <S.NamePart>
            { !isHomePath && (
              <S.MenuIcon onClick={() => moveSideBar()}>
                <Menu fontSize="medium" />
              </S.MenuIcon>
            )}
            <S.HotelName
              isHome={isHomePath}
              onClick={() => {
                if (!token) {
                  history.push('/');
                } else {
                  if (window.innerWidth <= Number(mobileRes2.replace('px', ''))) {
                    moveSideBar();
                    return;
                  }
                  moveSideBar(true);
                  setIsShowLogOut(true);
                }
              }}
            >
              {
                hotelData?.name ? (
                  <>
                    {hotelData?.name}
                    <S.Smaller>
                      {
                        hotelData?.branch ? ` - ${hotelData?.branch}` : ''
                      }
                    </S.Smaller>
                  </>
                ) : (
                  <LodgeFirst />
                )
              }
            </S.HotelName>
          </S.NamePart>
          {hotelData?.name ? (
            <>
              <LodgeFirst />
            </>
          ) : (null)}
        </S.Menu>
      </S.Container>
    </>
  );
};

export default LFHeader;
