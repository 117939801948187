import React from 'react';
import Logo from './Logo/Logo';
import * as S from './Footer.styled';
import Location from './Location/Location';

function Footer() {
  return (
    <S.FooterContainer>
      <S.FooterSection1>
        <S.JoinUs2><Logo width="28px" /></S.JoinUs2>
        <Location />
      </S.FooterSection1>
      <S.Copyright>
        {/* <S.Me>By Ikechi Anya</S.Me> */}
        {`Copyright © ${(new Date()).getFullYear()}`}
      </S.Copyright>
    </S.FooterContainer>
  );
}

export default Footer;
