import React, { useEffect, useState } from 'react';
import * as S from './Subscribe.styled';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectHotelData } from 'pages/redux';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import Loader from 'pages/components/Loader/Loader';
import { toast } from 'react-toastify';
import { useHotel, useSocketEmit } from '../../Navigation/hooks';
import { subscribe } from '../redux';
import { usePayment } from '../hooks';
import { selectRooms } from 'redux/global';

export const getChannelCost = (roomCount: number) => {
  const min = 5;
  const grade1 = 20;
  const grade2 = 150;
  const threshold = 1;
  const leastCost = 35000 * threshold;
  const grade1Cost = (3000 * threshold);
  const grade2Cost = (500 * threshold);
  const grade3Cost = (250 * threshold);
  let ret = 0;
  if (roomCount <= min) {
    ret = leastCost;
  } else if (roomCount <= grade1) {
    const grade1Rooms = roomCount - min;
    ret = leastCost + (grade1Rooms * grade1Cost);
  } else if (roomCount <= grade2) {
    const grade2Rooms = roomCount - grade1;
    ret = leastCost + ((grade1 - min) * grade1Cost) + (grade2Rooms * grade2Cost);
  } else {
    const grade3 = roomCount - grade2;
    ret = leastCost + ((grade1 - min) * grade1Cost) + ((grade2 - grade1) * grade2Cost)
      + (grade3 * grade3Cost);
  }
  return ret;
};

export const getCost = (roomCount: number, isChannel?: boolean) => {
  const min = 5;
  const grade1 = 30;
  const grade2 = 300;
  const threshold = 1;
  const leastCost = 15000 * threshold;
  const grade1Cost = (2000 * threshold);
  const grade2Cost = (850 * threshold);
  const grade3Cost = (200 * threshold);
  let ret = 0;
  if (roomCount <= min) {
    ret = leastCost;
  } else if (roomCount <= grade1) {
    const grade1Rooms = roomCount - min;
    ret = leastCost + (grade1Rooms * grade1Cost);
  } else if (roomCount <= grade2) {
    const grade2Rooms = roomCount - grade1;
    ret = leastCost + ((grade1 - min) * grade1Cost) + (grade2Rooms * grade2Cost);
  } else {
    const grade3 = roomCount - grade2;
    ret = leastCost + ((grade1 - min) * grade1Cost) + ((grade2 - grade1) * grade2Cost)
      + (grade3 * grade3Cost);
  }
  return ret + (isChannel ? getChannelCost(roomCount) : 0);
};

const Subscribe = ({ onExit }:{ onExit: Function }) => {
  const rooms = useAppSelector(selectRooms);
  const isLoadingRooms = !rooms;
  const [roomCount, setRoomCount] = useState<number>(rooms?.length || 0);
  const [countError, setCountError] = useState<string>('');
  const [isChannel, setIsChannel] = useState(true);
  const hotelData = useAppSelector(selectHotelData);
  const { updateHotel } = useHotel();
  const socketEmit = useSocketEmit();
  const dispatch = useAppDispatch();

  const dayDiff0 = new Date(hotelData?.expires || '').getTime() - new Date().getTime();
  const monthDiff0 = Math.ceil(12 - (dayDiff0 / (60 * 60 * 24 * 30 * 1000)));
  const monthDiff = monthDiff0 < 12 ? monthDiff0 < 0 ? 0 : monthDiff0 : 12;
  const [months, setMonths] = useState<number>(monthDiff);

  const totalCost0 = Math.ceil((getCost(roomCount, isChannel) * months) / 500) * 500;
  const totalCost = months === 12 ? totalCost0 * (93 / 100) : totalCost0;

  const paymentDetails = {
    flutterDesc: `Subscription ${isChannel ? ' with Channel Manager ' : ''}payment`,
    flutterTitle: `Subscription${isChannel ? ' with Channel Manager' : ''}`,
    totalCost,
    paymentDescription: `${roomCount} rooms_${months} months${isChannel ? '_channel active' : ''}`
  };

  const subscribeCall = async (closePayment: () => void) => {
    const expires = +new Date(hotelData?.expires || 0) > +new Date() ?
      new Date(hotelData?.expires || 0) : new Date();
    expires.setMonth(expires.getMonth() + months);

    let channelExpiry = hotelData?.channelExpiry;
    if (isChannel) {
      const cDate = new Date(hotelData?.channelExpiry || 0);
      if (!hotelData?.channelExpiry || +cDate < +new Date()) {
        channelExpiry = new Date(expires).toISOString();
      } else {
        cDate.setMonth(cDate.getMonth() + months);
        channelExpiry = cDate.toISOString();
      }
    }
    const billingDate = new Date().toISOString();
    const res = await dispatch(subscribe({
      maxRooms: roomCount.toString(),
      expires: expires.toISOString(),
      channelExpiry,
      billingDate,
    }));

    if (res.status === 'success') {
      updateHotel({
        expires: expires.toISOString(),
        maxRooms: roomCount.toString(),
        channelExpiry,
        billingDate,
      } as any);
      socketEmit('update_hotel', {
        hotelData: {
          expires: expires.toISOString(),
          maxRooms: roomCount.toString(),
          channelExpiry,
          billingDate
        }
      });
      toast('Successfully subscribed to Lodgefirst', { type: 'success' });
      closePayment();
      onExit();
    } else {
      toast(res.data, { type: 'error' });
      closePayment();
    }
  };

  const { isLoading, beginPayment } = usePayment({
    finalFunction: subscribeCall,
    onExit,
    paymentDetails
  });

  const onClickPay = () => {
    setCountError('');
    if (!roomCount) {
      setCountError('Room amount is needed');
      return;
    }
    if (roomCount < (rooms || []).length) {
      setCountError(`Rooms is lower than your current ${(rooms || []).length} rooms. Delete some rooms first`);
      return;
    }

    beginPayment();
  };

  useEffect(() => {
    if (rooms?.length) {
      setRoomCount(rooms.length);
    }
  }, [rooms]);

  return (
    <>
      {
        (isLoading || isLoadingRooms) ? <Loader isSticky skipSideBar /> : (
          <S.Container>
            <S.Header>SUBSCRIBE</S.Header>
            <EscapeButton onClick={() => onExit()} isRedBg isNoBg />
            <S.BottomPart>
              <S.RoomsFlex>
                <S.Rooms>Rooms</S.Rooms>
                <S.InputDiv>
                  <S.Input
                    type="number"
                    isError={!!countError}
                    value={roomCount.toString()}
                    onChange={(e) => {
                      setCountError('');
                      if (e.target.value.includes('e') || e.target.value.includes('-')) {
                        return;
                      }
                      setRoomCount(Number(e.target.value));
                    }}
                  />
                </S.InputDiv>
              </S.RoomsFlex>
              {countError ? <S.Error>{countError}</S.Error> : null}
              <S.FlexSet
                onClick={() => setIsChannel(!isChannel)}
              >
                <S.Label>
                  With
                  {' '}
                  <S.CM>Channel Manager</S.CM>
                </S.Label>
                <S.Select1
                  type="checkbox"
                  readOnly
                  checked={isChannel}
                />
              </S.FlexSet>
              <S.DurationFlex>
                <S.Duration>Billing cycle</S.Duration>
                <S.InputDiv1>
                  <S.Select
                    onChange={(e) => {
                      setMonths(
                        Number(e.target.value.replace(' months', '').replace(' month', ''))
                      );
                    }}
                  >
                    {
                      [...Array(monthDiff).keys()].map((diff) => (
                        <option key={`user_${diff}`} selected={diff === months - 1}>
                          {`${diff + 1} month${diff === 0 ? '' : 's'}`}
                        </option>
                      ))
                    }
                  </S.Select>
                </S.InputDiv1>
              </S.DurationFlex>
              <S.Info>
                Get
                <S.TenOff>7% off</S.TenOff>
                with a 12 month cycle
              </S.Info>
              <S.Price>
                {
                  roomCount <= 10000 ? <>{`₦${totalCost.toLocaleString()}`}</> : (
                    <>
                      <S.ContactUs>Contact us</S.ContactUs>
                      <S.Email>ceo@lodgefirst.com</S.Email>
                    </>
                  )
                }
              </S.Price>
              <S.SlashPrice>
                {(months === 12 && roomCount <= 10000) ? <s>{`₦${totalCost0.toLocaleString()}`}</s> : null}
              </S.SlashPrice>
              <S.Button
                onClick={() => {
                  if (roomCount <= 10000) onClickPay();
                }}
                isDisabled={roomCount > 10000}
              >
                Proceed to pay
              </S.Button>
            </S.BottomPart>
          </S.Container>
        )
      }
    </>
  );
};

export default Subscribe;
